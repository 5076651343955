// Variables
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Color system
$mainblue : #1285ef;
$buttonblue : #0060f1;
$btnhover : #0052cd;
$textlable : #171717;
$white : #ffffff;
$black : #000000;

$support : #FF5139;
$background : #f9f9f9;
$bordergray : #e8e8e8;
$yellow : #ffe600;

$graytext : #6c6c6c;
$lightgray : #f5f5f5;
$lightgray_2 : #e5e3e5;
$navy : #2e4681;

$lightblue : #e5f2fd;
$darknavy : #373843;

$bglable : #eff1f4;
$disabledtext : #bcbcbc;

// scss-docs-start gray-color-variables
$white:    #fff !default;
$gray-100: #F5F5F5 !default;
$gray-150: #EEEEEE !default;
$gray-200: #E8E8E8 !default;
$gray-300: #dddddd !default;
$gray-400: #D7DCE5 !default;
$gray-500: #AFB0B2 !default;
$gray-550: #969696 !default;
$gray-600: #666666 !default;
$gray-700: #6c6c6c !default;
$gray-800: #373843 !default;
$gray-900: #171717 !default;
$black:    #000 !default;
// scss-docs-end gray-color-variables

// fusv-disable
// scss-docs-start gray-colors-map
$grays: (
  "100": $gray-100,
  "150": $gray-150,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900
) !default;
// scss-docs-end gray-colors-map
// fusv-enable

// scss-docs-start color-variables
$blue:    #1285EF !default;
$indigo:  #2E4681 !default;
$purple:  #6f42c1 !default;
$pink:    #d63384 !default;
$red:     #FF0000 !default;
$orange:  #fd7e14 !default;
$yellow:  #ffc107 !default;
$green:   #198754 !default;
$teal:    #20c997 !default;
$cyan:    #0dcaf0 !default;
$white_cyan: #F5F8FA !default;
$darkqaua: #186C6D !default;
$linen: #F9F0E9 !default;
// scss-docs-end color-variables

// scss-docs-start colors-map
$colors: (
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "black":      $black,
  "white":      $white,
  "quill-gray": $gray-400,
  "gray":       $gray-600,
  "gray-dark":  $gray-800
) !default;
// scss-docs-end colors-map


// scss-docs-start theme-color-variables
$primary:       #0060F1 !default;
$secondary:     $gray-550 !default;
$success:       $green !default;
$select:        rgba(18, 133, 239, 0.1);
$info:          $blue !default;
$warning:       $yellow !default;
$danger:        $red !default;
$light:         $gray-100 !default;
$dark:          $gray-900 !default;
// scss-docs-end theme-color-variables

// scss-docs-start theme-colors-map
$theme-colors: (
  "primary":    $primary,
  "secondary":  $secondary,
  "secondary-150":  $gray-150,
  "secondary-400": $gray-400,
  "secondary-550": $gray-550,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "aquahaze": $white_cyan,
  "darkaqua": $darkqaua,
  "linen": $linen,

) !default;
// scss-docs-end theme-colors-map




// Typography
//
// Font, line-height, and color for body text, headings, and more.

@import url("https://cdn.jsdelivr.net/gh/orioncactus/pretendard@v1.3.9/dist/web/variable/pretendardvariable.min.css");
@font-face {
  font-family: 'yg-jalnan';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_four@1.2/JalnanOTF00.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'GmarketSansBold';
  src: url('https://fastly.jsdelivr.net/gh/projectnoonnu/noonfonts_2001@1.1/GmarketSansBold.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
// scss-docs-start font-variables
// stylelint-disable value-keyword-case 
$font-family-sans-serif:     Pretendard, "Pretendard Variable", -apple-system, BlinkMacSystemFont, system-ui, Roboto, "Helvetica Neue", "Segoe UI", "Apple SD Gothic Neo", "Noto Sans KR", "Malgun Gothic", "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", sans-serif;
$font-family-monospace:       SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
// stylelint-enable value-keyword-case

// $font-size-root affects the value of `rem`, which is used for as well font sizes, paddings, and margins
// $font-size-base affects the font size of the body text
$font-size-root:              null !default;
$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$font-size-sm:                $font-size-base * .8 !default;
$font-size-lg:                $font-size-base * 1.1 !default;

$line-height-base:            1.5 !default;
$line-height-sm:              1.25 !default;
$line-height-lg:              2 !default;

$font-size-base:              1rem !default; // Assumes the browser default, typically `16px`
$h1-font-size:                $font-size-base * 3.75 !default;
$h2-font-size:                $font-size-base * 3 !default;
$h3-font-size:                $font-size-base * 2.25 !default;
$h4-font-size:                $font-size-base * 1.5 !default;
$h5-font-size:                $font-size-base * 1.1 !default;
$h6-font-size:                $font-size-base !default;

$h1-font-size-m:                $font-size-base * 2.2 !default;
$h2-font-size-m:                $font-size-base * 1.5 !default;
$h3-font-size-m:                $font-size-base * 1.3 !default;
$h4-font-size-m:                $font-size-base * 1.2 !default;
$h5-font-size-m:                $font-size-base * 1.1 !default;
$h6-font-size-m:                $font-size-base !default;

//font(body text / header는 _basic.scss, mcommon.scss 파일에)
$body-text-20 : $font-size-base * 1.3;
$body-text-18 : $font-size-base * 1.1;
$body-text-16 : $font-size-base;
$body-text-14 : $font-size-base * .9;
$body-text-12 : $font-size-base * .8;

// scss-docs-start font-sizes
$font-sizes: (
  1: $h1-font-size,
  2: $h2-font-size,
  3: $h3-font-size,
  4: $h4-font-size,
  5: $h5-font-size,
  5\.5: $body-text-20,
  5\.9: $body-text-18,
  6: $h6-font-size,
  7: $body-text-14 ,
  8: $body-text-12
) !default;
// scss-docs-end font-sizes





// scss-docs-start spacer-variables-maps
$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  2\.5: $spacer * .75,
  3: $spacer,
  3\.5: $spacer * 1.25,
  4: $spacer * 1.5,
  4\.25: $spacer * 2,
  4\.5: $spacer * 2.5,
  5: $spacer * 3,
  5\.5: $spacer * 3.5,
  6: $spacer * 4,
) !default;
// scss-docs-end spacer-variables-maps

// scss-docs-start border-variables
$border-width:                1px !default;
$border-widths: (
  1: 1px,
  2: 2px,
  3: 3px,
  4: 4px,
  5: 5px
) !default;

$border-style:                solid !default;
$border-color:                $bordergray !default;
$border-color-translucent:    rgba($black, .175) !default;
// scss-docs-end border-variables

// scss-docs-start border-radius-variables
$border-radius:               .375rem !default;
$border-radius-sm:            .25rem !default;
$border-radius-lg:            .5rem !default;
$border-radius-xl:            1rem !default;
$border-radius-2xl:           2rem !default;
$border-radius-pill:          50rem !default;
// scss-docs-end border-radius-variables

// scss-docs-end box-shadow-variables

$component-active-color:      $white !default;
$component-active-bg:         $primary !default;


// Badges

// scss-docs-start badge-variables
$badge-font-size:                   .8em !default;
$badge-font-weight:                 700 !default;
$badge-color:                       $white !default;
$badge-padding-y:                   .35em !default;
$badge-padding-x:                   .65em !default;
$badge-border-radius:               $border-radius !default;
// scss-docs-end badge-variables

// Body
//
// Settings for the `<body>` element.

$body-bg:                   $white !default;
$body-color:                $gray-900 !default;
$body-text-align:           null !default;

// Prefix for :root CSS variables

$variable-prefix:             bs- !default; // Deprecated in v5.2.0 for the shorter `$prefix`
$prefix:                      $variable-prefix !default;

// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

// scss-docs-start input-btn-variables
$input-btn-padding-y:         .375rem !default;
$input-btn-padding-x:         .75rem !default;
$input-btn-font-family:       null !default;
$input-btn-font-size:         $font-size-base !default;
$input-btn-line-height:       $line-height-base !default;

$input-btn-focus-width:         .25rem !default;
$input-btn-focus-color-opacity: .25 !default;
$input-btn-focus-color:         rgba($component-active-bg, $input-btn-focus-color-opacity) !default;
$input-btn-focus-blur:          0 !default;
$input-btn-focus-box-shadow:    0 0 $input-btn-focus-blur $input-btn-focus-width $input-btn-focus-color !default;

$input-btn-padding-y-sm:      .25rem !default;
$input-btn-padding-x-sm:      .5rem !default;
$input-btn-font-size-sm:      $font-size-sm !default;

$input-btn-padding-y-lg:      .5rem !default;
$input-btn-padding-x-lg:      1rem !default;
$input-btn-font-size-lg:      $font-size-lg !default;

$input-btn-border-width:      $border-width !default;
// scss-docs-end input-btn-variables

// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

// scss-docs-start btn-variables
$btn-padding-y:               $input-btn-padding-y !default;
$btn-padding-x:               $input-btn-padding-x !default;
$btn-font-family:             $input-btn-font-family !default;
$btn-font-size:               $input-btn-font-size !default;
$btn-line-height:             $input-btn-line-height !default;
$btn-white-space:             null !default; // Set to `nowrap` to prevent text wrapping

$btn-padding-y-sm:            $input-btn-padding-y-sm !default;
$btn-padding-x-sm:            $input-btn-padding-x-sm !default;
$btn-font-size-sm:            $input-btn-font-size-sm !default;

$btn-padding-y-lg:            $input-btn-padding-y-lg !default;
$btn-padding-x-lg:            $input-btn-padding-x-lg !default;
$btn-font-size-lg:            $input-btn-font-size-lg !default;

$btn-border-width:            $input-btn-border-width !default;

$btn-font-weight:             400 !default;
$btn-box-shadow:              inset 0 1px 0 rgba($white, .15), 0 1px 1px rgba($black, .075) !default;
$btn-focus-width:             $input-btn-focus-width !default;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow !default;
$btn-disabled-opacity:        .65 !default;
$btn-active-box-shadow:       inset 0 3px 5px rgba($black, .125) !default;

$btn-link-color:              var(--#{$prefix}link-color) !default;
$btn-link-hover-color:        var(--#{$prefix}link-hover-color) !default;
$btn-link-disabled-color:     $gray-600 !default;

// Allows for customizing button radius independently from global border radius
$btn-border-radius:           $border-radius !default;
$btn-border-radius-sm:        $border-radius-sm !default;
$btn-border-radius-lg:        $border-radius-lg !default;

$btn-transition:              color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

$btn-hover-bg-shade-amount:       15% !default;
$btn-hover-bg-tint-amount:        15% !default;
$btn-hover-border-shade-amount:   20% !default;
$btn-hover-border-tint-amount:    10% !default;
$btn-active-bg-shade-amount:      20% !default;
$btn-active-bg-tint-amount:       20% !default;
$btn-active-border-shade-amount:  25% !default;
$btn-active-border-tint-amount:   10% !default;
// scss-docs-end btn-variables




// Navs

// scss-docs-start nav-variables
$nav-link-padding-y:                .5rem !default;
$nav-link-padding-x:                1rem !default;
$nav-link-font-size:                null !default;
$nav-link-font-weight:              null !default;
$nav-link-color:                    var(--#{$prefix}link-color) !default;
$nav-link-hover-color:              var(--#{$prefix}link-hover-color) !default;
$nav-link-transition:               color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out !default;
$nav-link-disabled-color:           $gray-600 !default;

$nav-tabs-border-color:             $gray-300 !default;
$nav-tabs-border-width:             $border-width !default;
$nav-tabs-border-radius:            $border-radius !default;
$nav-tabs-link-hover-border-color:  $gray-200 $gray-200 $nav-tabs-border-color !default;
$nav-tabs-link-active-color:        $gray-700 !default;
$nav-tabs-link-active-bg:           $body-bg !default;
$nav-tabs-link-active-border-color: $gray-300 $gray-300 $nav-tabs-link-active-bg !default;

$nav-pills-border-radius:           $border-radius !default;
$nav-pills-link-active-color:       $component-active-color !default;
$nav-pills-link-active-bg:          $component-active-bg !default;
// scss-docs-end nav-variables


// Navbar

// scss-docs-start navbar-variables
$navbar-padding-y:                  $spacer * .5 !default;
$navbar-padding-x:                  null !default;

$navbar-nav-link-padding-x:         $spacer  !default;

$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
$nav-link-height:                   $font-size-base * $line-height-base + $nav-link-padding-y * 2 !default;
$navbar-brand-height:               $navbar-brand-font-size * $line-height-base !default;
$navbar-brand-padding-y:            ($nav-link-height - $navbar-brand-height) * .5 !default;
$navbar-brand-margin-end:           1rem !default;

$navbar-toggler-padding-y:          .25rem !default;
$navbar-toggler-padding-x:          .75rem !default;
$navbar-toggler-font-size:          $font-size-lg !default;
$navbar-toggler-border-radius:      $btn-border-radius !default;
$navbar-toggler-focus-width:        $btn-focus-width !default;
$navbar-toggler-transition:         box-shadow .15s ease-in-out !default;

$navbar-light-color:                rgba($black, .55) !default;
$navbar-light-hover-color:          rgba($black, .7) !default;
$navbar-light-active-color:         rgba($black, .9) !default;
$navbar-light-disabled-color:       rgba($black, .3) !default;
$navbar-light-toggler-icon-bg:      url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-light-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-light-toggler-border-color: rgba($black, .1) !default;
$navbar-light-brand-color:          $navbar-light-active-color !default;
$navbar-light-brand-hover-color:    $navbar-light-active-color !default;
// scss-docs-end navbar-variables

// scss-docs-start navbar-dark-variables
$navbar-dark-color:                 rgba($white, .55) !default;
$navbar-dark-hover-color:           rgba($white, .75) !default;
$navbar-dark-active-color:          $white !default;
$navbar-dark-disabled-color:        rgba($white, .25) !default;
$navbar-dark-toggler-icon-bg:       url("data:image/svg+xml,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'><path stroke='#{$navbar-dark-color}' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/></svg>") !default;
$navbar-dark-toggler-border-color:  rgba($white, .1) !default;
$navbar-dark-brand-color:           $navbar-dark-active-color !default;
$navbar-dark-brand-hover-color:     $navbar-dark-active-color !default;
// scss-docs-end navbar-dark-variables

//modal
$modal-fade-transform: none;
$modal-transition: 0;

//container
$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1024px,
);

//미디어쿼리
@mixin mobile-s{
  @media(max-width: 359px){
      @content;
  }
}

@mixin mobile{
  @media(max-width: 767px){
      @content;
  }
}

@mixin tablet{
  @media(max-width: 991px){
      @content;
  }
}