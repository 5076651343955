@import "../../variables";

.post_card, .card{
    border: none;
    border-radius: 0;

    &:hover{
        .story_img{
            img{
                transform: scale(1.1);
            }
        }
    }

    button{
        padding: 0;
    }

    .story_img{
        width: 100%;
        aspect-ratio: 184 / 122;
        border: 1px solid $bordergray;
        border-radius: 0.625rem;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
            transition: 0.2s;
        }
    }
    
    .card-body{
        gap: 0.5rem;
        padding: 0;
        text-align: left;

        .category{
            padding-top: 0.56rem;
            font-size: $body-text-14;
            
            p{
                color: $mainblue;
            }
        }

        .title{
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; 
            -webkit-box-orient: vertical;
            line-height: normal;
            color: $textlable;
        }

        .writer{
            .writer_name{
                font-size: $body-text-14;
            }

            small{
                font-size: $body-text-12;
            }
        }
    }
}

.post_item{
    border-bottom: 1px solid $bordergray;
    padding-bottom: 0.875rem;

    &:hover{
        .card-body{
            .card_body_content{
                .desc{
                    p{
                        color: $black;
                    }
                }
            }
        }
    }

    button{
        display: block;
        padding: 0;
        width: 100%;
    }    

    .card-body{
        width: 100%;

        .card-text-content{
            width: calc(100% - 9.375rem - 5.75rem);
        }

        .category{   
            width: 100%;
            padding-bottom: 0.5rem;
            
            p{
                text-align: left;
                color: $mainblue;
                font-size: $body-text-12;
            }
        }

        .card_body_content{
            width: 100%;
            gap: 0.75rem;
            text-align: left;
    
            .title{
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                word-break: break-all;
                line-height: normal;
                color: $textlable;
                }
    
            .desc{
                p{
                    overflow: hidden;
                    text-overflow: ellipsis;
                    display: -webkit-box;
                    -webkit-line-clamp: 2; 
                    -webkit-box-orient: vertical;
                    line-height: normal;
                    font-size: $body-text-14;
                    color: $graytext;
                    transition: 0.2s;
                }
            }

            .writer{
                .writer_info{
                    gap: 0.625rem;
                    font-size: $body-text-12;

                    small{
                        font-size: $body-text-12;
                    }
                }
            }
        }
    }

    .story_img{
        width: 9.375rem;
        min-width: 9.375rem;
        margin-left: 5.75rem;
        aspect-ratio: 184 / 122;
        border: 1px solid $bordergray;
        border-radius: 0.625rem;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .writer{
        .avatar{
            width: 1.25rem;
            height: 1.25rem;

            img{
                vertical-align: top;
            }
        }
    }
}

.writer{
    gap: 0.5rem;

    .avatar{
        width: 1.875rem;
        height: 1.875rem;
        border-radius: 50%;
        overflow: hidden;

        img{
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .writer_info{
        .writer_name{     
            line-height: normal;
            margin-bottom: 0;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }

        small{
            font-size: $body-text-14;
            color: $graytext;
        }
    }
}